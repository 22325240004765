import config from '../../configuration.json';
import axios from 'axios';
import QueryHandler from '../utils/QueryHandler';

const url = `${config.VUE_APP_API_URL}/producers`;

axios.defaults.withCredentials = true;

class ProducerService {
  static async getAll(params = {}) {
    const resultUrl = QueryHandler.buildQuery(`${url}`, params);
    const res = await axios.get(resultUrl);
    return res.data;
  }
  static async create({ name }) {
    const res = await axios.post(url, { name });
    return res.data;
  }
  static async update({ id, name }) {
    const res = await axios.put(`${url}/${id}`, { name });
    return res.data;
  }
}

export default ProducerService;
