<template>
  <title-wrapper :title="`${$t('components.producers.title')} (${count})`">
    <my-data-table
      :data="producers"
      :headers="headers"
      :loading="loading"
      :length="length"
      :page.sync="params.page"
      :disable-sort="false"
      :sortBy="sortBy"
      :sortDesc="sortDesc"
      @update:sort-by="sortByFunc"
      @update:sort-desc="sortDescFunc"
    >
      <template v-slot:filters>
        <v-col cols="10">
          <v-text-field
            class="stretch-height"
            v-model="params.search"
            hide-details
            :placeholder="$t('helpers.filterName')"
            solo
          ></v-text-field
        ></v-col>
        <v-col cols="6" sm="1">
          <v-btn color="primary" elevation="0" fab dark block @click="onSearch">
            <v-icon>mdi-magnify</v-icon>
          </v-btn></v-col
        >
        <v-col cols="6" sm="1">
          <v-btn color="primary" elevation="0" fab dark block @click="clearParams">
            <v-icon>mdi-cancel</v-icon>
          </v-btn></v-col
        >
      </template>
      <template v-slot:actions="slotProps">
        <v-list-item class="hover-item-popup" @click="editProducer(slotProps.item)">
          <v-list-item-content>{{ $t('components.producers.changeName') }}</v-list-item-content>
        </v-list-item>
      </template>
      <template v-slot:below>
        <v-row
          ><v-col><v-divider></v-divider></v-col
        ></v-row>
        <v-row
          ><v-col>
            <primary-button :onClick="() => createProducer()"
              ><v-icon class="mr-4">mdi-plus-circle-outline</v-icon
              >{{ $t('components.producers.addProducer') }}</primary-button
            >
          </v-col></v-row
        >
      </template>
    </my-data-table>
    <change-address-modal
      :open="modal"
      :title="
        mode === modalModes.EDIT
          ? $t('components.producers.modal.titleOptionOne')
          : $t('components.producers.modal.titleOptionTwo')
      "
      :agree="$t('components.producers.modal.agree')"
      :disagree="$t('components.producers.modal.disagree')"
      v-on:decline="decline"
      v-on:accept="accept"
      :loading="loadingSaveButton"
    >
      <v-form v-model="isFormValid" ref="form" class="mt-4">
        <v-row>
          <v-col class="py-0 py-sm-2" col="4">
            <h2 class="text font-weight-bold text-color">{{ $t('helpers.filterName') }}</h2>
            <v-text-field
              v-model="producer.name"
              :rules="validator.requiredRules"
              outlined
              solo
              elevation="0"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </change-address-modal>
  </title-wrapper>
</template>
<script>
import TitleWrapper from '../../../../components/TitleWrapper.vue';
import MyDataTable from '../../../../components/MyDataTable.vue';
import PrimaryButton from '../../../../components/buttons/PrimaryButton.vue';
import ChangeAddressModal from '../../../../components/modals/ChangeAddressModal.vue';
import modalModes from '../../../../static/modalModes.js';
import { validators } from '../../../../validators/Validators';
import ProducerService from '../../../../services/ProducerService';
import { sortHandler } from '../../../../mixins/sortHandler';
import { formMixin } from '../../../../mixins/formMixin';

export default {
  name: 'Producers',
  components: { TitleWrapper, MyDataTable, PrimaryButton, ChangeAddressModal },
  mixins: [sortHandler, formMixin],
  data() {
    return {
      isFormValid: false,
      modal: false,
      mode: '',
      loadingSaveButton: false,
      count: 0,
      modalModes: modalModes,
      itemsPerPage: 6,
      length: 0,
      producers: [],
      producer: {
        name: '',
        id: '',
      },
      loading: true,
      params: {
        search: '',
        page: 1,
        limit: 6,
        sort: '',
      },
    };
  },

  watch: {
    'params.page': function () {
      this.getFiltered();
    },
  },
  methods: {
    async accept() {
      try {
        this.loadingSaveButton = true;
        if (this.mode === modalModes.NEW) {
          await ProducerService.create(this.producer);
        } else {
          await ProducerService.update(this.producer);
        }
        this.getFiltered();
        this.modal = false;
        this.$refs.form.reset();
      } catch (error) {
        console.error(error);
      } finally {
        this.producer.name = '';
        this.producer.id = '';
        this.loadingSaveButton = false;
      }
    },
    decline() {
      this.modal = false;
    },
    editProducer({ _id, name }) {
      this.producer.id = _id;
      this.producer.name = name;
      this.openModal(modalModes.EDIT);
    },
    createProducer() {
      this.openModal(modalModes.NEW);
    },
    openModal(mode) {
      this.mode = mode;
      this.modal = true;
    },
    clearParams() {
      this.params = {
        search: '',
        page: 1,
        limit: 6,
        sort: '',
      };
      this.getFiltered();
    },
    onSearch() {
      this.params.page = 1;
      this.getFiltered();
    },
    async getFiltered() {
      try {
        const response = await ProducerService.getAll(this.params);
        this.producers = response.data;
        this.count = response.count;

        this.length = Math.ceil(response.count / this.itemsPerPage);
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    fetchSort() {
      this.getFiltered();
    },
  },
  computed: {
    headers() {
      return [
        { text: this.$i18n.t('helpers.filterName'), value: 'name' },
        { text: this.$i18n.t('helpers.amount'), value: 'products' },
        { text: '', value: 'actions', align: 'end', sortable: false },
      ];
    },

    validator() {
      return validators;
    },
  },
  activated() {
    this.getFiltered();
  },
};
</script>
<style lang="scss" scoped></style>
